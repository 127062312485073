import {
  CircularProgress,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import Modal, { ModalProps } from "..";
import { Cancel } from "@mui/icons-material";
import { IDossierViewModel } from "viewModels";
import { ModalContent, ModalHeader, TestModal } from "./styles";
import { useEffect, useState } from "react";
import { useDossier } from "contexts";

interface PreviewDossierProps extends ModalProps {
  dossier: IDossierViewModel;
}

const DossierPreview: React.FC<PreviewDossierProps> = ({
  dossier,
  ...props
}: PreviewDossierProps) => {
  const theme = useTheme();
  const { getDossierPreview, loadingDossierPreview } = useDossier();
  const [localDossier, setLocalDossier] = useState<IDossierViewModel>(dossier);

  useEffect(() => {
    const fetchSummary = async () => {
      if (props.open && !dossier.resume) {
        const data = await getDossierPreview(dossier.id);
        console.log(data)
        if (data) {
          setLocalDossier((prev) => ({ ...prev, resume: data.resume }));
        }
      }
    };

    fetchSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, dossier]);

  return (
    <Modal
      {...props}
      sx={{
        width: "50vw",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ModalHeader>
        <Typography variant="h6" component="h2" color={"primary"}>
          Preview do resumo <b>{dossier.id}</b>
        </Typography>
        <IconButton size="small" onClick={props.onClose} color="error">
          <Cancel />
        </IconButton>
      </ModalHeader>
      <ModalContent>
      {localDossier.resume && 
        <div className="resume">
          <h1>Situação</h1>
          <p>
            {
              localDossier.resume?.situation
            }
          </p>
          <h1>Resumo</h1>
          <p>
            
            {
              localDossier.resume?.conclusion
            }
          </p>
        </div>
        }
        {loadingDossierPreview && (
          <div className="loading">
            <CircularProgress size={20} />
            <Typography
              paddingLeft={1}
              variant="caption"
              color="text"
              sx={{ fontStyle: "italic" }}
            >
              Carregando Resumo...
            </Typography>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};

export default DossierPreview;
