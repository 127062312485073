import {
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Modal, { ModalProps } from "..";
import { Cancel, Visibility } from "@mui/icons-material";
import { IFontReportViewModel, IFontTestViewModel } from "viewModels";
import { ModalContent, ModalHeader } from "./styles";
import { FontStatus } from "types/enums";
import { useState } from "react";
import { Modals } from "components";

interface SummaryTestProps extends ModalProps {
  report: IFontTestViewModel;
}

const SummaryTest: React.FC<SummaryTestProps> = ({
  report,
  ...props
}: SummaryTestProps) => {
  const theme = useTheme();
  const [showLogModal, setShowLogModal] = useState<boolean>(false);
  const [currentFont, setCurrentFont] = useState<IFontReportViewModel>();

  function StatusLabel(status: FontStatus): {
    label: string;
    color: "success" | "error" | "warning" | "default";
  } {
    switch (status) {
      case FontStatus.AVAILABLE:
        return { label: "Disponível", color: "success" };
      case FontStatus.UNAVAILABLE:
        return { label: "Indisponível", color: "error" };
      case FontStatus.PROCESSING:
        return { label: "Pendente", color: "warning" };
      default:
        return { label: "Desconhecido", color: "default" };
    }
  }

  return (
    <Modal
      {...props}
      sx={{
        width: "80vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ModalHeader>
        <Typography variant="h6" component="h2" color={"primary"}>
          Detalhes do relátorio <b>{report.id}</b>
        </Typography>
        <IconButton size="small" onClick={props.onClose} color="error">
          <Cancel />
        </IconButton>
      </ModalHeader>
      <ModalContent>
        <Table stickyHeader aria-label="listDossiers">
          <TableHead>
            <TableRow
              sx={{
                th: {
                  backgroundColor: "white",
                  "& p": {
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                  },
                  "& div": {
                    display: "flex",
                    alignItems: "center",

                    "& button": {
                      ml: 1,
                    },
                  },
                },
              }}
            >
              <TableCell>
                <Typography>Nome da Fonte</Typography>
              </TableCell>
              <TableCell>
                <Typography>Status</Typography>
              </TableCell>
              <TableCell>
                <Typography>Log</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {report.fonts?.map((font) => {
              const statusInfo = StatusLabel(font.status);
              return (
                <TableRow
                  key={font.font.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>
                    <Tooltip title={font.font.name} placement="top-start">
                      <Typography>{font.font.name}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      <Chip
                        variant="outlined"
                        label={statusInfo.label}
                        color={statusInfo.color}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      disabled={!font.log}
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        setCurrentFont(font);
                        setShowLogModal(true);
                      }}
                    >
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </ModalContent>
      {currentFont && showLogModal && (
        <Modals.FontTestLogModal
          open={showLogModal}
          onClose={() => setShowLogModal(false)}
          font={currentFont}
        />
      )}
    </Modal>
  );
};

export default SummaryTest;
