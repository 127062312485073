import styled from "styled-components";

export const Container = styled.div`
  .MuiOutlinedInput-input {
    padding: 0.5rem;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 20px;
  overflow: auto;

  .loading {
    padding: 10px;
    display: flex;
    gap: 10px;
  }

  .resume {
    width: 100%;
    max-width: 800px;
    margin: 20px 0;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .resume h1 {
    font-size: 24px;
    font-weight: 600; 
    color: #333;
    margin-bottom: 10px; 
  }

  .resume p {
    font-size: 19px; 
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
  }
`;

export const TestModal = styled.div`
  background-color: red;
`