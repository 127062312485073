import styled from "styled-components";

export const Page = styled.div`
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  width: 100%;
  margin-top: 4.8rem;
  padding: 1rem 1.5rem;
  @media (min-width: 1024px) {
    padding: 1rem 8rem;
  }
`;

export const Content = styled.div`
  display: flex;
  padding: 1rem;
  border-radius: 4px;
  width: 100%;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  overflow: auto;
  background-color: ${({ theme: { colors } }) => colors.background_white};
  @media (min-width: 1024px) {
    padding: 3rem;
  }
  & .table {
    min-width: 90rem;
    & .truncate {
      width: 15rem;
      overflow: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const ListRecklessTrader = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 1rem;
  width: 100%;
  border-radius: 4px;
  & .content {
    max-height: 20rem;
    padding-top: 0.5rem;
    overflow: auto;
    width: 100%;
    margin-bottom: 1rem;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & .endline {
    align-self: flex-end;
  }
`;
export const FormControl = styled.form`
  width: 100%;
`;

export const ButtonContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
