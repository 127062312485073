import axios from "axios";
import { IOrderedWithPaginationInputModel } from "inputModels";
import api from "services/api";
import { ILogUserViewModel, IResponse, IVoidResponse } from "viewModels";

const listAll = <T>(input?: IOrderedWithPaginationInputModel<T>) =>
  new Promise<IResponse<ILogUserViewModel[]>>((resolve, reject) => {
    api
      .get(
        `loguser/?page=${input?.page}&take=${input?.take}${
          input?.term ? `&term=${input?.term}` : ""
        }${input?.filter ? `&filter=${input?.filter}` : ""}&${input?.orderBy
          ?.map(
            (o, i) =>
              `orderBy[${i}].key=${o.key}&orderBy[${i}].direction=${o.direction}`
          )
          .join("&")}`
      )
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getUserLogDetails = (
  logId: string
): Promise<IResponse<ILogUserViewModel>> => {
  return new Promise<IResponse<ILogUserViewModel>>((resolve, reject) => {
    api
      .get(`loguser/get-details/${logId}`)
      .then(({ data }) => resolve(data)) // resolve com o retorno esperado
      .catch(reject);
  });
};

export const exportReportFinancialFonts = () => {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`loguser/export-user-logs`, {
        responseType: "blob",
      })
      .then(({ data }) => {
        download(data, `fontes-${Date.now()}`);
        resolve();
      })
      .catch(reject);
  });
};

const download = (data: any, name: string) => {
  const blob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const uri = window.URL.createObjectURL(blob);

  if (!uri) return;
  const link = document.createElement("a");

  link.href = uri;
  link.setAttribute("download", name);
  link.rel = "noopener noreferrer";
  link.referrerPolicy = "no-referrer";
  link.click();

  link.parentElement?.removeChild(link);
  window.URL.revokeObjectURL(uri);
};

const requestInfoCNPJ = (input?: { cnpj: string }) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    axios
      .create({
        baseURL: "https://receitaws.com.br/v1/cnpj/cnpj",
      })
      .get(`https://receitaws.com.br/v1/cnpj/${input}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const LogUserController = {
  listAll,
  getUserLogDetails,
  requestInfoCNPJ,
};
