import { LogUserContextProps } from "contexts-types";
import { Columns, FilterProps, Props } from "props";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { ILogUserViewModel } from "viewModels";
import { useAuth } from "./auth";
import { IOrderFieldInputModel } from "inputModels";
import { LogUserController } from "controllers";
import { TypeUser } from "types/enums";

const PAGE_SIZE = 10;
const LogUserContext = createContext<LogUserContextProps>(
  {} as LogUserContextProps
);
const LogUser: React.FC<Props> = ({ children }) => {
  const { user, logged } = useAuth();
  const [orderBy, setOrder] = useState<IOrderFieldInputModel<Columns>[]>([]);
  const [filter, setFilter] = useState<FilterProps>();
  const [logsUser, setLogsUser] = useState<ILogUserViewModel[]>([]);
  // const [reportsFinancials, setReportsFinancials] = useState<
  //   ILogUserViewModel[]
  // >([]);
  const [enableFecthUserLogs, setEnableFecthUserLogs] =
    useState<boolean>(false);

  const {
    data: results,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    ["logsUser", orderBy, filter],
    ({ pageParam = 1 }) =>
      LogUserController.listAll({
        orderBy,
        page: pageParam,
        take: PAGE_SIZE,
        term: filter?.term,
        filter: filter?.term ? filter?.filter : undefined,
      }).then((res) => res.data),
    {
      enabled:
        (logged && enableFecthUserLogs && user.typeUser === TypeUser.ADM) ||
        user.typeUser === TypeUser.USER, // ADM ou EMPRESA tem acesso aos logs
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.length < PAGE_SIZE ? undefined : nextPage;
      },
    }
  );

  const getUserLogDetails = async (logId: string) => {
    const logAlreadyHasDetails = logsUser.some(
      (log) => log.id === logId && log.fontsLogs?.length > 0
    );

    if (logAlreadyHasDetails) {
      return; // Não faz a chamada se os detalhes já estão carregados
    }

    // Faz a requisição para buscar os detalhes
    const response = await LogUserController.getUserLogDetails(logId);

    // Atualiza o estado com os detalhes recebidos
    setLogsUser((prevLogs) =>
      prevLogs.map((log) => {
        if (log.id === logId) {
          return { ...log, fontsLogs: response.data.fontsLogs || [] };
        }
        return log;
      })
    );
  };

  const handleFetchLogUser = () => {
    refetch();
  };

  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  useEffect(() => {
    setLogsUser(results?.pages?.flat() ?? []);
  }, [results, setLogsUser]);

  return (
    <LogUserContext.Provider
      value={{
        logsUser,
        setLogsUser,
        isLoading,
        orderBy,
        setOrder,
        filter,
        setFilter,
        handleFetchLogUser,
        handleFetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        setEnableFecthUserLogs,
        getUserLogDetails,
      }}
    >
      {children}
    </LogUserContext.Provider>
  );
};

export const useLogUser: () => LogUserContextProps = () =>
  useContext(LogUserContext);

export default LogUser;
