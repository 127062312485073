import {
  KeyboardArrowDown,
  KeyboardDoubleArrowDown,
  Refresh,
  Sort,
  Flag,
  Info,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Zoom,
  useTheme,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AppBar, FilterInput, Modals } from "components";
import { toPattern } from "vanilla-masker";
import { MASK_CNPJ, MASK_CPF, MASK_IDENTY } from "../../constants";
import {
  useAlert,
  useApp,
  useAuth,
  useDossier,
  useLogUser,
  useModel,
} from "contexts";
import { AuthController, DossierController } from "controllers";
import {
  IModelDossierInputModel,
  IRequestDossierInputModel,
} from "inputModels";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import {
  DossierPdfStatus,
  DossierStatus,
  Gender,
  ProcessStatus,
  SearchType,
  TypeUser,
} from "types/enums";
import { Cnpj, Cpf } from "utils/masks";
import { v4 as uuid } from "uuid";
import {
  IAvailableExternalFontViewModel,
  IDossierViewModel,
  IVoidResponse,
} from "viewModels";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ptBR } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import { ContentTable } from "./styles";
import { Columns } from "props";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { AxiosError } from "axios";
import { ModelDossierController } from "controllers/ModelDossierController";

interface IKeyword {
  id: string;
  value: string;
}

type RequestDossierInputModelWithKeywords = Omit<
  IRequestDossierInputModel,
  "keywords"
> & {
  keywords?: IKeyword[];
};

const Search = () => {
  const MAX_DATE = dayjs() as unknown as Date;
  const MIN_DATE = dayjs("1900-01-01") as unknown as Date;
  const {
    dossiers,
    setDossiers,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    setFilter,
    orderBy,
    setOrder,
    handleFetchNextPage,
    handleFetch,
    selectedDownloadType,
    setSelectedDownloadType,
    handleDownload,
    isDownloading,
  } = useDossier();
  const alert = useAlert();
  const { setLoading } = useApp();
  const { handleFetchLogUser } = useLogUser();
  const confirm = useAlert();
  const { user, setUser } = useAuth();
  const { models, handleFetch: handleFetchModels } = useModel();
  const [pdfId, setPdfId] = useState<string>();
  const [selectedGender, setSelectedGender] = useState<Gender>(Gender.MALE);
  const [, setDownloading] = useState<string>();
  const [showSummaryDossier, setShowSummaryDossier] = useState(false);
  const [showPreviewDossier, setShowPreviewDossier] = useState(false);
  const [showSelectExternalFonts, setShowSelectExternalFonts] = useState(false);
  const [showGenerateAgain, setShowGenerateAgain] = useState(false);
  const [dossierSelected, setDossierSelected] = useState<IDossierViewModel>(
    {} as IDossierViewModel
  );
  const [externalFonts, setExternalFonts] = useState<
    IAvailableExternalFontViewModel[]
  >([]);
  const [isOpenPrivacyPolicyModal, setIsOpenPrivacyPolicyModal] =
    useState<boolean>(false);
  const [isConfirmPrivacyPolicyModal, setIsConfirmPrivacyPolicyModal] =
    useState<boolean>(false);
  const [isOpenTermsConditions, setIsOpenTermsConditions] =
    useState<boolean>(false);
  const [isConfirmTermsConditions, setIsConfirmTermsConditions] =
    useState<boolean>(false);
  const [mask, setMask] = useState("");
  const [isValidDocument, setIsValidDocument] = useState(false);
  const [isCpf, setIsCpf] = useState(false);
  const navigate = useNavigate();

  useQuery(
    "external-fonts-search",
    () =>
      DossierController.getExternalFonts().then((res) => {
        setExternalFonts(res.data);
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const [requesting, setRequesting] = useState(false);

  const [defaultValues] = useState<RequestDossierInputModelWithKeywords>({
    description: "",
    motherName: "",
    fatherName: "",
    processNumber: "",
    document: "",
    identityNumber: "",
    expeditionOrgan: "",
    federativeUnit: "",
    city: "",
    neighborhood: "",
    cep: "",
    address: "",
  });

  const {
    control,
    handleSubmit,
    register,
    reset,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
  });

  const data = watch();

  const {
    fields: keywords,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "keywords",
  });

  const theme = useTheme();

  const handleFormSubmit = () => {
    if (requesting) return;
    setRequesting(true);
    if (isCpf) {
      setExternalFonts((prev) =>
        prev.filter(
          (font) =>
            font.value.type === SearchType.CPF ||
            font.value.type === SearchType.BOTH
        )
      );
    } else {
      setExternalFonts((prev) =>
        prev.filter(
          (font) =>
            font.value.type === SearchType.CNPJ ||
            font.value.type === SearchType.BOTH
        )
      );
    }

    setShowSelectExternalFonts(true);
  };

  const handleRequestDossier = async (selectedExternalFonts: string[]) => {
    const keys =
      data?.keywords
        ?.map((k) => k.value.trim())
        .filter((k) => !!k && k !== "") ?? [];

    DossierController.requestDossier({
      ...data,
      keywords:
        keys.length > 0
          ? keys
          : inputRef.current?.value
          ? [inputRef.current.value]
          : undefined,
      gender: isCpf ? selectedGender : undefined,
      selectedExternalFonts,
      document: data.document.replace(/\D/g, ""),
      identityNumber: data.identityNumber
        ? data.identityNumber.replace(/\D/g, "")
        : undefined,
      federativeUnit: data.federativeUnit ? data.federativeUnit : undefined,
      city: data.city ? data.city : undefined,
      expeditionOrgan: data.expeditionOrgan ? data.expeditionOrgan : undefined,
      description: data.description ? data.description : undefined,
    })
      .then(async (res) => {
        if (res.success) {
          setRequesting(false);
          await confirm.show({
            type: "alert",
            title: "Sucesso:",
            description: "O Dossiê foi requerido e será processado em breve.",
          });

          handleFetch();
          handleFetchLogUser();
          reset({
            description: "",
            document: "",
            keywords: [],
          });

          if (inputRef.current) inputRef.current.value = "";
        }
      })
      .catch(async (res) => {
        if (res.response.data.message?.includes("Saldo insuficiente")) {
          await confirm.show({
            type: "error",
            title: "Falha ao requerer",
            timeout: 5000,
            description: "A conta não possui saldo suficiente para a operação.",
            retry: {
              show: false,
            },
            cancel: {
              label: "Ok",
            },
          });
        } else {
          await confirm.show({
            type: "error",
            title: "Falha ao requerer",
            timeout: 5000,
            description:
              "Algum erro inesperado aconteceu ao solicitar o dossiê.\nTente novamente...",
            retry: {
              show: false,
            },
            cancel: {
              label: "Ok",
            },
          });
        }
      })
      .finally(() => setRequesting(false));
  };

  const handleCreateModelAndGenerateDossier = async (
    modelName: string,
    selectedFonts: string[]
  ): Promise<boolean> => {
    if (!modelName.trim()) {
      alert.show({
        type: "error",
        title: "Erro",
        description: "O nome do modelo não pode estar vazio.",
        cancel: { show: false },
      });
      return false;
    }

    if (models?.some((item) => item.name === modelName)) {
      alert.show({
        type: "error",
        title: "Erro",
        description: "Um modelo com este nome já existe. Escolha outro nome.",
        cancel: { show: false },
      });
      return false;
    }

    try {
      const input: IModelDossierInputModel = {
        name: modelName,
        fonts: selectedFonts,
        createdById: user.id,
        type: selectedFonts.some(
          (key) =>
            externalFonts.find((f) => f.key === key)?.value.type ===
            SearchType.CPF
        )
          ? SearchType.CPF
          : selectedFonts.some(
              (key) =>
                externalFonts.find((f) => f.key === key)?.value.type ===
                SearchType.CNPJ
            )
          ? SearchType.CNPJ
          : SearchType.BOTH,
      };

      await ModelDossierController.insert(input)
        .then(() => {
          handleFetchModels();
        })
        .catch(() => {
          alert.show({
            type: "error",
            title: "Erro",
            description: "Erro ao criar o modelo. Tente novamente.",
          });
        });

      alert.show({
        type: "alert",
        title: "Sucesso",
        description: "Modelo criado com sucesso!",
        timeout: 3000,
      });

      await handleRequestDossier(selectedFonts);
      return true;
    } catch (error) {
      alert.show({
        type: "error",
        title: "Erro",
        description:
          "Erro ao criar o modelo ou gerar o dossiê. Tente novamente.",
      });
      return false;
    }
  };

  const handleRequestNewTry = (id: string) => {
    DossierController.requestNewTryToProcess(id).then(() => {
      setDossiers((prev) => {
        const newDossiers = [...prev];
        const idx = newDossiers.findIndex((d) => d.id === id);
        if (idx !== -1) newDossiers[idx].status = DossierStatus.PENDING;
        return newDossiers;
      });
    });
  };

  const handleRequestPdf = (id: string, option: "pdf" | "zip") => {
    DossierController.requestPdf(id)
      .then(async (res) => {
        if (res.success) {
          if (option === "pdf") {
            await confirm.show({
              type: "alert",
              title: "Sucesso:",
              description: "O Pdf está sendo gerado.",
              timeout: 5000,
            });
          } else {
            await confirm.show({
              type: "alert",
              title: "Sucesso:",
              description: "O Zip está sendo gerado.",
              timeout: 5000,
            });
          }
        }
      })
      .catch((err: AxiosError) => {
        const error = err.response?.data as IVoidResponse;
        if (error.errorCode === "EX00000") {
          console.clear();
          handleDownload(id, option);
          return;
        }
        confirm.show({
          type: "error",
          title: "Falha ao solicitar",
          timeout: 5000,
          description:
            "Algum erro inesperado aconteceu ao gerar o arquivo.\nTente novamente...",
          retry: {
            show: false,
          },
          cancel: {
            label: "Ok",
          },
        });
      });
  };

  const handleSort = (key: Columns) => () => {
    setOrder((prev) => {
      const newOrder = [...prev];
      const oldOrderIdx = prev.findIndex((o) => o.key === key);
      if (oldOrderIdx === -1) {
        newOrder.push({
          key,
          direction: "asc",
        });
      } else if (newOrder[oldOrderIdx].direction === "asc") {
        newOrder[oldOrderIdx].direction = "desc";
      } else {
        newOrder.splice(oldOrderIdx, 1);
      }

      return newOrder;
    });
  };

  const handleFailErrorDossier = (dossierId: string) => {
    navigate("/logsUser", { state: { dossierId } });
  };

  const handleModalFailInfo = () => {
    alert.show({
      type: "alert",
      title: "Alerta:",
      description: "Por favor, entre em contato com a equipe de suporte.",
      timeout: 3000,
    });
  };
  const handleChange = (event: SelectChangeEvent<Gender>) => {
    const { value } = event.target;
    setSelectedGender(value as unknown as Gender);
  };

  const getSortIcon = (key: Columns) => (
    <IconButton
      data-testid="sortIconButton"
      size="small"
      onClick={handleSort(key)}
    >
      {orderBy.find((o) => o.key === key) ? (
        <KeyboardArrowDown
          sx={{
            transition: "all 0.5s ease-in",
            transform:
              orderBy.find((o) => o.key === key)?.direction === "asc"
                ? "rotate(-180deg)"
                : "",
          }}
        />
      ) : (
        <Sort />
      )}
    </IconButton>
  );

  useEffect(() => {
    data.document.length > 14 ? setMask(MASK_CNPJ) : setMask(MASK_CPF);
  }, [data.document]);

  useEffect(() => {
    data.document.length > 14
      ? setIsValidDocument(cnpj.isValid(data.document))
      : setIsValidDocument(cpf.isValid(data.document));
  }, [data.document]);

  useEffect(() => {
    if (isValidDocument) {
      clearErrors("document");
    }
  }, [clearErrors, isValidDocument]);

  useEffect(() => {
    if (data.document.length === 14 && isValidDocument) {
      setIsCpf(true);
    } else {
      setIsCpf(false);
    }
  }, [data.document.length, isValidDocument]);

  const isDisableBuildPdf = (
    dossierStatus?: DossierStatus,
    processStatus?: ProcessStatus
  ) => {
    if (dossierStatus === undefined && processStatus === undefined) {
      return true;
    }
    if (dossierStatus !== undefined && processStatus === undefined) {
      if (dossierStatus === DossierStatus.DONE) {
        return false;
      }
      return true;
    }
    if (processStatus !== undefined && dossierStatus === undefined) {
      if (processStatus === ProcessStatus.DONE) {
        return false;
      }
      return true;
    }
    if (dossierStatus !== undefined && processStatus !== undefined) {
      if (
        dossierStatus === DossierStatus.DONE &&
        processStatus === ProcessStatus.DONE
      ) {
        return false;
      }
      return true;
    }
  };
  const labelTypeDossier = (
    dossierStatus?: DossierStatus,
    processStatus?: ProcessStatus
  ) => {
    if (
      dossierStatus === DossierStatus.PROCESSING_ERROR ||
      processStatus === ProcessStatus.PROCESSING_ERROR
    ) {
      return "Falha";
    }
    if (
      dossierStatus === DossierStatus.PENDING ||
      processStatus === ProcessStatus.PENDING
    ) {
      return "Pendente";
    }
    if (
      dossierStatus === DossierStatus.PROCESSING ||
      processStatus === ProcessStatus.PROCESSING
    ) {
      return "Processando";
    }
    if (
      dossierStatus === DossierStatus.DONE &&
      processStatus === ProcessStatus.DONE
    ) {
      return "Finalizado";
    }
    if (dossierStatus === undefined && processStatus === ProcessStatus.DONE) {
      return "Finalizado";
    }
    if (dossierStatus === DossierStatus.DONE && processStatus === undefined) {
      return "Finalizado";
    }
    if (
      dossierStatus === DossierStatus.CANCELED ||
      processStatus === ProcessStatus.CANCELED
    ) {
      return "Cancelado";
    }

    return "Falha";
  };

  const handleConfirmationConditions = () => {
    setIsConfirmTermsConditions(true);
    setUser((prev) => ({
      ...prev,
      acceptedPrivacyPolicy: true,
      acceptedTermConditions: true,
    }));
    AuthController.updateConditionsAccepted(user.id, true);
  };

  // States
  useEffect(() => {
    if (data.description === "DEBUG") console.log({ dossiers });
  }, [data.description, dossiers]);

  useEffect(() => {
    if (
      user.acceptedPrivacyPolicy !== true ||
      user.acceptedTermConditions !== true
    ) {
      setIsOpenPrivacyPolicyModal(true);
      if (isConfirmPrivacyPolicyModal) {
        setIsOpenPrivacyPolicyModal(false);
        setIsOpenTermsConditions(true);
      }
      if (isConfirmTermsConditions) {
        setIsOpenTermsConditions(false);
      }
    }
  }, [
    user.acceptedPrivacyPolicy,
    user.acceptedTermConditions,
    isConfirmPrivacyPolicyModal,
    isConfirmTermsConditions,
  ]);

  useEffect(() => {
    setLoading(isDownloading);
  }, [isDownloading, setLoading]);

  return (
    <>
      <AppBar />
      <Container
        // maxWidth="xl"
        sx={{
          flexGrow: 1,
          minWidth: "90vw",
          minHeight: "100vh",
          overflowY: "auto",
          pt: 10,
          pb: 4,
        }}
      >
        <Paper sx={{ p: 5, mb: 5 }}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={2} padding="0 1rem">
              <Grid item xs={12}>
                <Typography component="p" variant="h4" color="primary">
                  Novo Dossiê
                </Typography>
              </Grid>
              <Grid item xs={12} sm={isCpf ? 6 : 12} md={2}>
                <Controller
                  name="document"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Documento é obrigatório",
                    },
                    minLength: {
                      value: 14,
                      message: "Documento incompleto",
                    },
                    validate: {
                      validateDocument: () => {
                        return isValidDocument ? true : "Documento inválido";
                      },
                    },
                  }}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Informe o CPF ou CNPJ"
                      value={mask ? toPattern(value ?? "", mask) : mask}
                      error={!!errors.document?.message}
                      helperText={errors.document?.message}
                      placeholder="Informe o CPF ou CNPJ"
                      onChange={onChange}
                      {...rest}
                    />
                  )}
                />
              </Grid>

              {isCpf && (
                <>
                  <Grid item xs={12} sm={6} md={4.5}>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Nome"
                          error={!!errors.name?.message}
                          helperText={errors.name?.message}
                          placeholder="Informe o nome"
                          onChange={onChange}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={1.5}>
                    <Controller
                      name="birthDate"
                      control={control}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          localeText={
                            ptBR.components.MuiLocalizationProvider.defaultProps
                              .localeText
                          }
                        >
                          <DateField
                            fullWidth
                            size="small"
                            label="Data de Nascimento"
                            format="DD/MM/YYYY"
                            value={dayjs(value)}
                            maxDate={dayjs(MAX_DATE)}
                            minDate={dayjs(MIN_DATE)}
                            helperText={errors.birthDate?.message}
                            FormHelperTextProps={{ sx: { color: "#d32f2f" } }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: errors.birthDate?.message
                                    ? "#d32f2f"
                                    : "#0000003b",
                                },
                              },
                              "& .MuiInputLabel-root": {
                                color: errors.birthDate?.message
                                  ? "#d32f2f"
                                  : "#1e0b0b99",
                              },
                            }}
                            onChange={onChange}
                            {...rest}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.5}>
                    <Controller
                      name="identityNumber"
                      control={control}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <TextField
                          fullWidth
                          size="small"
                          inputProps={{
                            "aria-label": "identityNumber",
                          }}
                          label="Número de Identidade"
                          value={
                            MASK_IDENTY
                              ? toPattern(value ?? "", MASK_IDENTY)
                              : MASK_IDENTY
                          }
                          placeholder="Informe o número de identidade"
                          onChange={onChange}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={1.5}>
                    <Controller
                      name="expeditionOrgan"
                      control={control}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Órgão Expedidor"
                          placeholder="Informe o órgão expedidor"
                          onChange={onChange}
                          inputProps={{
                            "aria-label": "expeditionOrgan",
                          }}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              {isCpf && (
                <>
                  <Grid item xs={12} sm={5} md={5}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Nome da mãe"
                      disabled={requesting}
                      inputProps={{ maxLength: 100 }}
                      {...register("motherName")}
                      placeholder="Informe o nome da mãe"
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} md={5}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Nome do pai"
                      disabled={requesting}
                      inputProps={{ maxLength: 100 }}
                      {...register("fatherName")}
                      placeholder="Informe o nome do pai"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field: { onChange, value, ...rest } }) => (
                        <FormControl fullWidth size="small">
                          <InputLabel id="gender">Gênero</InputLabel>
                          <Select
                            labelId="gender"
                            label="Gênero"
                            fullWidth
                            size="small"
                            value={selectedGender}
                            onChange={handleChange}
                            {...rest}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                          >
                            <MenuItem value={Gender.FEMALE}>Feminino</MenuItem>
                            <MenuItem value={Gender.MALE}>Masculino</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={isCpf ? 7 : 12} md={isCpf ? 9 : 7}>
                <TextField
                  fullWidth
                  size="small"
                  label="Descrição sobre o dossiê que será solicitado"
                  disabled={requesting}
                  inputProps={{ maxLength: 100 }}
                  {...register("description")}
                  placeholder="Digite as informações sobre o dossiê"
                />
              </Grid>
              <Grid item xs={12} sm={isCpf ? 5 : 12} md={isCpf ? 3 : 3}>
                <TextField
                  fullWidth
                  size="small"
                  label="Número de Processo"
                  disabled={requesting}
                  inputProps={{ maxLength: 100 }}
                  {...register("processNumber")}
                  placeholder="Digite o número de processo"
                  InputProps={{
                    inputComponent: InputMask as any,
                    inputProps: {
                      mask: "9999999-99.9999.9.99.9999",
                      maskChar: null,
                    },
                    endAdornment: (
                      <Tooltip
                        data-testid="tooltipCDA"
                        title={
                          <h1 style={{ fontSize: "1rem" }}>
                            O Número de Processo deve ser informado para que
                            fonte Busca por licitações no Portal da
                            Transparência faça a busca corretamente.
                          </h1>
                        }
                        placement="top"
                        arrow
                      >
                        <Info
                          color="primary"
                          sx={{ cursor: "pointer" }}
                          style={{ fontSize: "1.25rem" }}
                        />
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={8} md={8}>
                <Controller
                  name="address"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      size="small"
                      label="Logradouro"
                      placeholder="Informe o Logradouro"
                      onChange={onChange}
                      {...rest}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Controller
                  name="neighborhood"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Bairro"
                      placeholder="Informe a bairro"
                      onChange={onChange}
                      {...rest}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={8} md={8}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Cidade"
                      placeholder="Informe a cidade"
                      onChange={onChange}
                      {...rest}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={2} md={2}>
                <Controller
                  name="cep"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="CEP"
                      placeholder="Informe o CEP"
                      onChange={onChange}
                      InputProps={{
                        inputComponent: InputMask as any,
                        inputProps: {
                          mask: "99.999-999",
                          maskChar: null,
                        },
                      }}
                      {...rest}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={2} md={2}>
                <Controller
                  name="federativeUnit"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel id="uf">UF</InputLabel>
                      <Select
                        labelId="uf"
                        label="UF"
                        fullWidth
                        size="small"
                        value={value}
                        onChange={onChange}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        {...rest}
                      >
                        <MenuItem value={"AC"}>AC</MenuItem>
                        <MenuItem value={"AL"}>AL</MenuItem>
                        <MenuItem value={"AP"}>AP</MenuItem>
                        <MenuItem value={"AM"}>AM</MenuItem>
                        <MenuItem value={"BA"}>BA</MenuItem>
                        <MenuItem value={"CE"}>CE</MenuItem>
                        <MenuItem value={"DF"}>DF</MenuItem>
                        <MenuItem value={"ES"}>ES</MenuItem>
                        <MenuItem value={"GO"}>GO</MenuItem>
                        <MenuItem value={"MA"}>MA</MenuItem>
                        <MenuItem value={"MT"}>MT</MenuItem>
                        <MenuItem value={"MS"}>MS</MenuItem>
                        <MenuItem value={"MG"}>MG</MenuItem>
                        <MenuItem value={"PA"}>PA</MenuItem>
                        <MenuItem value={"PB"}>PB</MenuItem>
                        <MenuItem value={"PR"}>PR</MenuItem>
                        <MenuItem value={"PE"}>PE</MenuItem>
                        <MenuItem value={"PI"}>PI</MenuItem>
                        <MenuItem value={"RJ"}>RJ</MenuItem>
                        <MenuItem value={"RN"}>RN</MenuItem>
                        <MenuItem value={"RS"}>RS</MenuItem>
                        <MenuItem value={"RO"}>RO</MenuItem>
                        <MenuItem value={"RR"}>RR</MenuItem>
                        <MenuItem value={"SC"}>SC</MenuItem>
                        <MenuItem value={"SP"}>SP</MenuItem>
                        <MenuItem value={"SE"}>SE</MenuItem>
                        <MenuItem value={"TO"}>TO</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={9}>
                <TextField
                  size="small"
                  fullWidth
                  label="Palavras-chave"
                  multiline
                  inputRef={inputRef}
                  disabled={requesting}
                  inputProps={{ maxLength: 100 }}
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {keywords?.map(({ id, value }, idx) => (
                          <Chip
                            sx={{
                              maxWidth: 250,
                            }}
                            key={id}
                            label={value}
                            onDelete={() => remove(idx)}
                          />
                        ))}
                      </Box>
                    ),
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "," && inputRef.current?.value) {
                      const value = inputRef.current?.value
                        .replaceAll(",", "")
                        .trim();
                      if (value === "") return;
                      append({
                        id: uuid(),
                        value,
                      });
                      inputRef.current.value = "";
                    }
                  }}
                  placeholder="Informe as palavras-chave para a busca de mídias e internet separadas por vírgula"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ cursor: requesting ? "wait" : "pointer" }}
                >
                  <Typography variant="button">Gerar dossiê</Typography>
                  {requesting && (
                    <CircularProgress
                      size={15}
                      sx={{ ml: 1, color: "white" }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <ContentTable>
          <FilterInput onInputChange={setFilter} sx={{ pt: 1 }} />
          <Table stickyHeader aria-label="listDossiers">
            <TableHead>
              <TableRow
                sx={{
                  th: {
                    backgroundColor: "white",
                    "& p": {
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                    },
                    "& div": {
                      display: "flex",
                      alignItems: "center",

                      "& button": {
                        ml: 1,
                      },
                    },
                  },
                }}
              >
                <TableCell>
                  <Typography>#</Typography>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>CPF/CNPJ</Typography>
                    {getSortIcon("document")}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography>Descrição</Typography>
                </TableCell>
                <TableCell>
                  <Grid data-testid="gridDate">
                    <Typography>Data</Typography>
                    {getSortIcon("date")}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Status</Typography>
                    {getSortIcon("status")}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography>Exportar</Typography>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Monitoramento</Typography>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Red Flag</Typography>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Sumário</Typography>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Resumo</Typography>
                  </Grid>
                </TableCell>
                {/* <TableCell>
                  <Typography>Custo</Typography>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <Grid
                      item
                      alignItems="center"
                      sx={{ minHeight: "65px !important" }}
                    >
                      <CircularProgress size={15} />
                      <Typography
                        paddingLeft={1}
                        variant="caption"
                        color="text"
                        sx={{ fontStyle: "italic" }}
                      >
                        Carregando lista de dossiês...
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : !dossiers.length ? (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <Typography
                      paddingLeft={1}
                      variant="caption"
                      color="text"
                      sx={{ fontStyle: "italic" }}
                    >
                      Nenhum dossiê encontrado...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                dossiers.map((dossier, idx) => (
                  <TableRow
                    data-testid="tableRowDossier"
                    key={dossier.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {data.description === "DEBUG" && (
                      <p>
                        Dossiê: {dossier.status} Processo:{" "}
                        {dossier.processStatus}
                      </p>
                    )}
                    <TableCell>
                      <Typography color="primary">
                        {(idx + 1).toString().padStart(2, "000")}
                      </Typography>
                    </TableCell>
                    <TableCell data-testid="tableCellDocument">
                      <Typography>
                        {dossier.document.length === 11
                          ? Cpf(dossier.document)
                          : Cnpj(dossier.document)}
                      </Typography>
                    </TableCell>
                    <TableCell data-testid="tableCellDescription">
                      <Tooltip
                        title={
                          <h1 style={{ fontSize: 15 }}>
                            {dossier.description}
                          </h1>
                        }
                        followCursor
                        TransitionComponent={Zoom}
                      >
                        <Typography className="dossierDescription">
                          {(dossier?.description === ""
                            ? "-"
                            : dossier?.description) ?? "-"}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell data-testid="tableCellDate">
                      <Typography>
                        {moment(dossier.createdAt).format("DD/MM/yyyy HH:mm")}
                      </Typography>
                    </TableCell>
                    <TableCell data-testid="tableCellStatus">
                      {dossier.status === DossierStatus.PROCESSING_ERROR &&
                        (user?.typeUser === TypeUser.ADM ? (
                          <button
                            className="buttonError"
                            onClick={() => handleFailErrorDossier(dossier.id)}
                          >
                            <Chip
                              data-testid="chipStatusFail"
                              style={{ cursor: "pointer" }}
                              label={labelTypeDossier(
                                dossier.status,
                                dossier.processStatus
                              )}
                              variant="outlined"
                              title="Clique para ver o Log"
                              color={"error"}
                            />
                          </button>
                        ) : (
                          <button
                            className="buttonError"
                            onClick={handleModalFailInfo}
                          >
                            <Chip
                              data-testid="chipStatusFail"
                              style={{ cursor: "pointer" }}
                              label={labelTypeDossier(
                                dossier.status,
                                dossier.processStatus
                              )}
                              variant="outlined"
                              title="Clique para instruções"
                              color={"error"}
                            />
                          </button>
                        ))}
                      {dossier.status !== DossierStatus.PROCESSING_ERROR && (
                        <Chip
                          data-testid="chipStatus"
                          label={labelTypeDossier(
                            dossier.status,
                            dossier.processStatus
                          )}
                          variant="outlined"
                          color={
                            labelTypeDossier(
                              dossier.status,
                              dossier.processStatus
                            ) === "Pendente"
                              ? "warning"
                              : labelTypeDossier(
                                  dossier.status,
                                  dossier.processStatus
                                ) === "Processando"
                              ? "info"
                              : labelTypeDossier(
                                  dossier.status,
                                  dossier.processStatus
                                ) === "Finalizado"
                              ? "success"
                              : labelTypeDossier(
                                  dossier.status,
                                  dossier.processStatus
                                ) === "Cancelado"
                              ? "default"
                              : "error"
                          }
                        />
                      )}
                      {dossier.status === DossierStatus.PROCESSING_ERROR && (
                        <IconButton
                          data-testid="buttonFail"
                          onClick={() => handleRequestNewTry(dossier.id)}
                        >
                          <RefreshIcon color="error" />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell data-testid="tableCellExport">
                      <ButtonGroup
                        disabled={isDisableBuildPdf(
                          dossier.status,
                          dossier.processStatus
                        )}
                      >
                        {dossier.pdfStatus === DossierPdfStatus.CANCELED ? (
                          <>
                            <Button
                              title="Clique para tentar novamente"
                              size="small"
                              color="error"
                              variant="outlined"
                              onClick={() =>
                                handleRequestPdf(
                                  dossier.id,
                                  selectedDownloadType
                                )
                              }
                            >
                              FALHA
                              <Refresh sx={{ pl: 1 }} />
                            </Button>
                          </>
                        ) : dossier.pdfStatus === DossierPdfStatus.BUILDING ? (
                          <>
                            <ButtonGroup
                              disabled={dossier.status !== DossierStatus.DONE}
                            >
                              <Button
                                sx={{ pointerEvents: "none" }}
                                size="small"
                                color="warning"
                                variant="outlined"
                              >
                                Gerando...
                              </Button>
                            </ButtonGroup>
                          </>
                        ) : (
                          <>
                            <Button
                              size="small"
                              color="error"
                              variant="outlined"
                              onClick={() => {
                                setSelectedDownloadType("pdf");
                                handleRequestPdf(dossier.id, "pdf");
                              }}
                            >
                              PDF
                            </Button>
                            <Button
                              size="small"
                              color="info"
                              variant="outlined"
                              onClick={() => {
                                setSelectedDownloadType("zip");
                                handleRequestPdf(dossier.id, "zip");
                              }}
                            >
                              ZIP
                            </Button>
                          </>
                        )}
                      </ButtonGroup>
                    </TableCell>
                    <TableCell data-testid="tableCellMonitoring">
                      <Button
                        variant="outlined"
                        color={
                          dossier.generateAgain === true
                            ? "info"
                            : dossier.generateAgain === false
                            ? "error"
                            : "warning"
                        }
                        disabled={isDisableBuildPdf(
                          dossier.status,
                          dossier.processStatus
                        )}
                        onClick={() => {
                          setShowGenerateAgain(true);
                          setDossierSelected(dossier);
                        }}
                      >
                        {dossier.generateAgain === true
                          ? "Monitorando"
                          : dossier.generateAgain === false
                          ? "Pausado"
                          : "Monitorar"}
                      </Button>
                    </TableCell>
                    <TableCell data-testid="tableCellFlag">
                      {!isDisableBuildPdf(
                        dossier.status,
                        dossier.processStatus
                      ) ? (
                        dossier.hasFlag ? (
                          <Flag style={{ color: "#e64c3c" }}></Flag>
                        ) : (
                          <Typography>Não possui</Typography>
                        )
                      ) : (
                        <Typography>Dossiê em processamento</Typography>
                      )}
                    </TableCell>
                    <TableCell data-testid="tableCellFlag">
                      {dossier.status === DossierStatus.DONE ? (
                        <Button
                          variant="outlined"
                          color="info"
                          onClick={() => {
                            setShowSummaryDossier(true);
                            setDossierSelected(dossier);
                          }}
                        >
                          Visualizar
                        </Button>
                      ) : (
                        <Typography>-</Typography>
                      )}
                    </TableCell>
                    <TableCell data-testid="tableCellFlag">
                      {dossier.status === DossierStatus.DONE ? (
                        <Button
                          variant="outlined"
                          color="info"
                          onClick={() => {
                            setShowPreviewDossier(true);
                            setDossierSelected(dossier);
                          }}
                        >
                          Preview
                        </Button>
                      ) : (
                        <Typography>-</Typography>
                      )}
                    </TableCell>
                    {/* <TableCell align="center">
                      {dossier.dossierValue ? (
                        dossier.isTrial ? (
                          <Tooltip
                            title={"Dossiê Trial"}
                            followCursor
                            TransitionComponent={Zoom}
                          >
                            <span className="dossie-value-trial">
                              R${" "}
                              {dossier.dossierValue
                                .toFixed(2)
                                .replace(".", ",")}
                            </span>
                          </Tooltip>
                        ) : (
                          <span className="dossie-value">
                            R${" "}
                            {dossier.dossierValue.toFixed(2).replace(".", ",")}
                          </span>
                        )
                      ) : (
                        <span className="dossie-value">-</span>
                      )}
                    </TableCell> */}
                  </TableRow>
                ))
              )}
              {hasNextPage && (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    {isFetchingNextPage ? (
                      <Grid
                        item
                        alignItems="center"
                        sx={{ minHeight: "65px !important" }}
                      >
                        <CircularProgress size={15} />
                        <Typography
                          paddingLeft={1}
                          variant="caption"
                          color="text"
                          sx={{ fontStyle: "italic" }}
                        >
                          Carregando itens...
                        </Typography>
                      </Grid>
                    ) : (
                      <Button
                        onClick={() => handleFetchNextPage()}
                        sx={{ px: 2 }}
                      >
                        <KeyboardDoubleArrowDown />
                        <Typography paddingLeft={2} variant="button">
                          Carregar mais...
                        </Typography>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ContentTable>
        {isOpenPrivacyPolicyModal && (
          <Modals.PrivacyPolicyModal
            isOpen={isOpenPrivacyPolicyModal}
            onClose={() => setIsOpenPrivacyPolicyModal(false)}
            onConfirm={() => setIsConfirmPrivacyPolicyModal(true)}
          />
        )}
        {isOpenTermsConditions && (
          <Modals.TermsConditions
            isOpen={isOpenTermsConditions}
            onClose={() => setIsOpenTermsConditions(false)}
            onConfirm={() => handleConfirmationConditions()}
          />
        )}
        {pdfId !== undefined && (
          <Modals.PdfViewer
            pdfId={pdfId}
            onDownload={() => setDownloading(undefined)}
            open={pdfId !== undefined}
            onClose={() => {
              setDownloading(undefined);
              setPdfId(undefined);
            }}
          />
        )}
        {showSelectExternalFonts && (
          <Modals.SelectExternalFonts
            externalFonts={externalFonts}
            open={showSelectExternalFonts}
            type={isCpf ? "cpf" : "cnpj"}
            onClose={() => {
              setShowSelectExternalFonts(false);
              setRequesting(false);
            }}
            onSelect={(selected) => {
              setShowSelectExternalFonts(false);
              handleRequestDossier(selected);
            }}
            onCreateModelAndGenerateDossier={(modelName, selectedFonts) =>
              handleCreateModelAndGenerateDossier(modelName, selectedFonts)
            }
          />
        )}
        {showSummaryDossier && (
          <Modals.SummaryDossier
            onClose={() => setShowSummaryDossier(false)}
            open={showSummaryDossier}
            dossier={dossierSelected}
          />
        )}
        {showPreviewDossier && (
          <Modals.DossierPreview
            onClose={() => setShowPreviewDossier(false)}
            open={showPreviewDossier}
            dossier={dossierSelected}
          />
        )}
        {showGenerateAgain && (
          <Modals.GenerateAgain
            externalFonts={externalFonts}
            dossier={dossierSelected}
            open={showGenerateAgain}
            onClose={() => {
              setShowGenerateAgain(false);
            }}
          />
        )}
      </Container>
    </>
  );
};

export default Search;
