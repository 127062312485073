import { Props } from "props";
import AppProvider, { useApp } from "./app";
import AuthProvider, { useAuth } from "./auth";
import AlertProvider, { useAlert } from "./alert";
import ModelProvider, { useModel } from "./model";
import DossierProvider, { useDossier } from "./dossier";
import CollaboratorUserProvider, {
  useCollaboratorUser,
} from "./collaboratorUser";
import CompanyUserProvider, { useCompanyUser } from "./companyUser";
import LogUserProvider, { useLogUser } from "./logUser";
import RecklessTraderProvider, { useRecklessTrader } from "./recklessTrader";
import PaymentProvider from "./payment";
import FontMonitoringProvider from "./fontMonitoring";
import { ConfigProvider } from "antd";
import locale from "antd/locale/pt_BR";
export {
  useApp,
  useAuth,
  useAlert,
  useModel,
  useDossier,
  useCollaboratorUser,
  useCompanyUser,
  useLogUser,
  useRecklessTrader,
};

const GlobalContext: React.FC<Props> = ({ children }: Props) => {
  return (
    <AppProvider>
      <AlertProvider>
        <AuthProvider>
          <LogUserProvider>
            <DossierProvider>
              <CompanyUserProvider>
                <CollaboratorUserProvider>
                  <RecklessTraderProvider>
                    <ModelProvider>
                      <PaymentProvider>
                        <FontMonitoringProvider>
                          <ConfigProvider
                            locale={locale}
                            theme={{
                              token: {
                                colorPrimary: "#293778",
                              },
                            }}
                          >
                            {children}
                          </ConfigProvider>
                        </FontMonitoringProvider>
                      </PaymentProvider>
                    </ModelProvider>
                  </RecklessTraderProvider>
                </CollaboratorUserProvider>
              </CompanyUserProvider>
            </DossierProvider>
          </LogUserProvider>
        </AuthProvider>
      </AlertProvider>
    </AppProvider>
  );
};

export default GlobalContext;
