import {
  IOrderedWithPaginationInputModel,
  IRequestDossierInputModel,
  IGenerateAgainInputModel,
} from "inputModels";
import api from "services/api";
import { DossierStatus } from "types/enums";
import {
  IDossierViewModel,
  IResponse,
  IVoidResponse,
  IAvailableExternalFontViewModel,
  IDossierSummaryViewModel,
  IDossierPreviewViewModel,
} from "viewModels";

const requestDossier = (input: IRequestDossierInputModel) =>
  new Promise<IResponse<string>>((resolve, reject) => {
    api
      .post("dossier", input)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getByUserId = <T>(input?: IOrderedWithPaginationInputModel<T>) =>
  new Promise<IResponse<IDossierViewModel[]>>((resolve, reject) => {
    api
      .get(
        `dossier/get-by-user-id?page=${input?.page}&take=${input?.take}${
          input?.term ? `&term=${input?.term}` : ""
        }${input?.filter ? `&filter=${input?.filter}` : ""}&${input?.orderBy
          ?.map(
            (o, i) =>
              `orderBy[${i}].key=${o.key}&orderBy[${i}].direction=${o.direction}`
          )
          .join("&")}`
      )
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const updateStatus = (id: string, status: DossierStatus) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .put(`dossier/update-status/${id}`, undefined, { params: { status } })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const requestNewTryToProcess = (id: string) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .put(`dossier/request-new-try-to-process/${id}`, undefined)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const download = (id: string, type: "pdf" | "zip" = "pdf") =>
  new Promise<string>((resolve, reject) => {
    api
      .get(`dossier/download/${id}`, { params: { type } })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const requestPdf = (id: string) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .get(`dossier/request-pdf/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getExternalFonts = () =>
  new Promise<IResponse<IAvailableExternalFontViewModel[]>>(
    (resolve, reject) => {
      api
        .get("dossier/get-external-fonts/")
        .then(({ data }) => resolve(data))
        .catch(reject);
    }
  );
const dossierGenerateAgain = (input: IGenerateAgainInputModel) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .post("dossier/generate-again", input)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getDossierSummary = (id: string) =>
  new Promise<IResponse<IDossierSummaryViewModel>>((resolve, reject) => {
    api
      .get(`dossier/get-summary/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getDossierPreview = (id: string) =>
  new Promise<IResponse<IDossierPreviewViewModel>>((resolve, reject) => {
    api
      .get(`dossier/get-resume/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const DossierController = {
  getExternalFonts,
  requestDossier,
  updateStatus,
  requestNewTryToProcess,
  getByUserId,
  requestPdf,
  download,
  dossierGenerateAgain,
  getDossierSummary,
  getDossierPreview,
};
