import { Cancel, LabelImportant, Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Modal } from "components";
import { ModalProps } from "..";
import { useEffect, useState } from "react";
import {
  IAvailableExternalFontViewModel,
  IModelDossierViewModel,
} from "viewModels";
import { Controller, useForm } from "react-hook-form";
import { IUpdateModelDossierInputModel } from "inputModels";
import { ModelDossierController } from "controllers/ModelDossierController";
import { useAlert, useAuth, useModel } from "contexts";
import { SearchType } from "types/enums";
import { v4 as uuid } from "uuid";

interface EditDossierModelModalProps extends ModalProps {
  model: IModelDossierViewModel;
  baseExternalFonts: IAvailableExternalFontViewModel[];
  type: SearchType;
}

const EditDossierModelModal: React.FC<EditDossierModelModalProps> = ({
  model,
  baseExternalFonts,
  type,
  ...props
}: EditDossierModelModalProps) => {
  const theme = useTheme();
  const alert = useAlert();
  const { models, setModels } = useModel();
  const [warningDisplayed, setWarningDisplayed] = useState<boolean>(false);
  const [selected, setSelected] = useState<IAvailableExternalFontViewModel[]>(
    []
  );
  const [requesting, setRequesting] = useState(false);
  const [externalFontsFiltered, setExternalFontsFiltered] = useState<
    IAvailableExternalFontViewModel[]
  >(model.fonts);
  const [defaultValues] = useState({
    name: model.name,
    filter: "",
  });
  const {
    control,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  const data = watch();
  const { user } = useAuth();

  const verifySelected = (
    newSelecteds: IAvailableExternalFontViewModel[],
    item: IAvailableExternalFontViewModel
  ) => {
    if (
      !newSelecteds.some((s) => s.value.type === SearchType.CPF) &&
      item.value.type === SearchType.CPF
    ) {
      setExternalFontsFiltered(
        baseExternalFonts.filter((f) => f.value.type !== SearchType.CNPJ)
      );
      if (
        newSelecteds.filter((sf) => sf.value.type === SearchType.CPF).length ===
        0
      ) {
        alert.show({
          type: "alert",
          title: `Fonte de CPF selecionada:`,
          description: `${item.value.name}`,
          subtitle:
            "As fontes disponíveis foram atualizadas para não conter fontes de CNPJ.",
          timeout: 10000,
        });
      }
    } else if (
      !newSelecteds.some((sf) => sf.value.type === SearchType.CNPJ) &&
      item.value.type === SearchType.CNPJ
    ) {
      setExternalFontsFiltered(
        baseExternalFonts.filter((f) => f.value.type !== SearchType.CPF)
      );
      if (
        newSelecteds.filter((sf) => sf.value.type === SearchType.CNPJ)
          .length === 0
      ) {
        alert.show({
          type: "alert",
          title: `Fonte de CNPJ selecionada:`,
          subtitle:
            "As fontes disponíveis foram atualizadas para não conter fontes de CPF.",
          description: `${item.value.name}`,
          timeout: 10000,
        });
      }
    }
  };

  const verifyDesselected = (
    newSelecteds: IAvailableExternalFontViewModel[],
    item: IAvailableExternalFontViewModel
  ) => {
    if (
      !newSelecteds.some((s) => s.value.type === SearchType.CPF) &&
      !newSelecteds.some((s) => s.value.type === SearchType.CNPJ) &&
      (item.value.type === SearchType.CPF ||
        item.value.type === SearchType.CNPJ)
    ) {
      setExternalFontsFiltered(baseExternalFonts);
      alert.show({
        type: "alert",
        title: "Atenção",
        description:
          "As fontes foram atualizadas para conter todas as fontes disponíveis",
        timeout: 10000,
      });
    }
  };

  const handleSelect = (item: IAvailableExternalFontViewModel) => () => {
    const newSelecteds = [...(selected || [])];
    const idx = newSelecteds.findIndex((ns) => ns.key === item.key);
    if (idx !== -1) {
      newSelecteds.splice(idx, 1);
      verifyDesselected(newSelecteds, item);
    } else {
      verifySelected(newSelecteds, item);
      newSelecteds.push(item);
    }

    if (selected?.length >= 20 && !warningDisplayed) {
      alert.show({
        type: "alert",
        title: "Atenção:",
        description:
          "Selecionar mais que 20 fontes gera custo adicional na geração de dossiês.",
        timeout: 10000,
      });
      setWarningDisplayed(true);
    }

    if (warningDisplayed && selected?.length <= 20) {
      setWarningDisplayed(false);
    }
    setSelected(newSelecteds);
  };

  const sortExternalFonts = (
    a: IAvailableExternalFontViewModel,
    b: IAvailableExternalFontViewModel
  ) => {
    const isSelectedA = selected?.some((sel) => sel.key === a.key);
    const isSelectedB = selected?.some((sel) => sel.key === b.key);

    if (isSelectedA && !isSelectedB) {
      return -1;
    } else if (!isSelectedA && isSelectedB) {
      return 1;
    } else {
      return 0;
    }
  };

  const handleUpdateModel = async () => {
    setRequesting(true);
    let hasError = false;

    if (!data.name) {
      setError("name", { message: "Informe o nome" });
      hasError = true;
    }
    if (
      models
        ?.filter((m) => m.id !== model.id)
        .some((item) => item.name === data.name)
    ) {
      setError("name", { message: "Nome do modelo já existe" });
      hasError = true;
    }

    if (hasError) {
      setRequesting(false);
      return;
    }
    const update: IUpdateModelDossierInputModel = {
      id: model.id,
      name: data.name,
      fonts: selected?.map((s) => s.key),
      updatebyid: user.id,
      type: selected?.some((s) => s.value.type === SearchType.CPF)
        ? SearchType.CPF
        : selected?.some((s) => s.value.type === SearchType.CNPJ)
        ? SearchType.CNPJ
        : SearchType.BOTH,
    };

    ModelDossierController.updateModel(update)
      .then(() => {
        setModels((prev) => {
          const index = prev.findIndex((m) => m.id === model.id);
          prev[index] = {
            ...prev[index],
            name: data.name,
            fonts: selected,
            updatebyid: user.id,
          };
          return [...prev];
        });
        setRequesting(false);
        props?.onClose();
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  useEffect(() => {
    setValue("name", model.name);
    setSelected(model.fonts);
  }, [model, setValue]);

  useEffect(() => {
    if (type === SearchType.CPF) {
      setExternalFontsFiltered(
        baseExternalFonts.filter((f) => f.value.type !== SearchType.CNPJ)
      );
    } else if (type === SearchType.CNPJ) {
      setExternalFontsFiltered(
        baseExternalFonts.filter((f) => f.value.type !== SearchType.CPF)
      );
    } else {
      setExternalFontsFiltered(baseExternalFonts);
    }
  }, [baseExternalFonts, type]);
  return (
    <Modal
      {...props}
      sx={{
        position: "relative",
        width: "30rem",
        overflowX: "hidden",
        overflowY: "auto",
        p: 1,
      }}
    >
      <IconButton
        size="small"
        onClick={props?.onClose}
        color="error"
        sx={{
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <Cancel />
      </IconButton>
      <Container>
        <Grid>
          <Typography variant="h5" color="primary" marginBottom={2}>
            Editar Modelo
          </Typography>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <TextField
                fullWidth
                error={!!errors.name?.message}
                helperText={errors.name?.message}
                onChange={onChange}
                value={value}
                size="small"
                label="Nome"
                {...rest}
              />
            )}
          />
          <Grid display="flex" justifyContent="end">
            <Controller
              control={control}
              name="filter"
              render={({ field: { onChange, value } }) => (
                <TextField
                  size="small"
                  label="Pesquisar Modelos"
                  variant="standard"
                  type="search"
                  autoComplete="off"
                  placeholder="O que deseja buscar?"
                  sx={{ width: 170 }}
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    sx: {
                      pl: 1,
                    },
                    endAdornment: <Search />,
                  }}
                />
              )}
            />
          </Grid>
          <Typography padding="0.5rem 0" fontSize={16}>
            Selecione as fontes para serem geradas:
          </Typography>
          <Grid
            container
            columns={{ xs: 2, sm: 4, md: 6 }}
            height="18rem"
            overflow="auto"
            border={`1px solid ${theme.palette.primary.light}`}
            borderRadius="6px"
            borderColor="primary"
          >
            {externalFontsFiltered
              ?.filter((f) =>
                data?.filter
                  ? f.value.name
                      .toLocaleLowerCase()
                      .includes(data?.filter.toLocaleLowerCase())
                  : externalFontsFiltered
              )
              .sort((a, b) => sortExternalFonts(a, b))
              .map((item) => (
                <Grid item xs={4} sm={8} md={6} key={item.key + uuid()}>
                  <ListItem
                    onClick={handleSelect(item)}
                    sx={{
                      transition: "all 0.5s ease-in-out",
                      cursor: "pointer",
                      borderRadius: 1,
                      userSelect: "none",
                      ":hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.05)",
                      },
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      <LabelImportant
                        color={
                          selected?.some((f) => f.key === item.key)
                            ? "warning"
                            : "inherit"
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={item.value.name} />
                  </ListItem>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid display="flex" justifyContent="center" marginTop="1.5rem">
          <Button
            style={{ width: "10rem" }}
            type="button"
            onClick={handleUpdateModel}
            disabled={selected?.length === 0 || requesting}
            variant="contained"
            sx={{ cursor: requesting ? "wait" : "pointer" }}
          >
            <Typography variant="button">Salvar</Typography>
            {requesting && (
              <CircularProgress size={15} sx={{ ml: 1, color: "white" }} />
            )}
          </Button>
        </Grid>
      </Container>
    </Modal>
  );
};

export default EditDossierModelModal;
