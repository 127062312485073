import {
  Cancel,
  Check,
  DoDisturb,
  KeyboardArrowDown,
  KeyboardDoubleArrowDown,
  Sort,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid2,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { Modal } from "components";
import { ModalProps } from "..";
import { useEffect, useState } from "react";
import { ICollaboratorUserViewModel } from "viewModels";
import { CollaboratorUserController } from "controllers";
import { ContentTable } from "pages/Search/styles";
import { useInfiniteQuery } from "react-query";
import { IOrderFieldInputModel } from "inputModels";
import { Columns } from "props";

interface CollaboratorListModalProps extends ModalProps {
  userId: string;
}

const CollaboratorListModal = ({
  userId,
  ...props
}: CollaboratorListModalProps) => {
  const theme = useTheme();
  const [collaborators, setCollaborators] = useState<
    ICollaboratorUserViewModel[]
  >([]);
  const [orderBy, setOrder] = useState<IOrderFieldInputModel<Columns>[]>([]);

  const {
    data: results,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    ["collaborators", orderBy],
    ({ pageParam = 1 }) =>
      CollaboratorUserController.getCollaboratorByUserId(userId, {
        orderBy,
        page: pageParam,
        take: 15,
      }).then((res) => res.data),
    {
      enabled: !!userId,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.length < 15 ? undefined : nextPage;
      },
    }
  );

  const handleSort = (key: Columns) => () => {
    setOrder((prev) => {
      const newOrder = [...prev];
      const oldOrderIdx = prev.findIndex((o) => o.key === key);
      if (oldOrderIdx === -1) {
        newOrder.push({
          key,
          direction: "asc",
        });
      } else if (newOrder[oldOrderIdx].direction === "asc") {
        newOrder[oldOrderIdx].direction = "desc";
      } else {
        newOrder.splice(oldOrderIdx, 1);
      }
      return newOrder;
    });
  };

  const getSortIcon = (key: Columns) => (
    <IconButton size="small" onClick={handleSort(key)}>
      {orderBy.find((o) => o.key === key) ? (
        <KeyboardArrowDown
          sx={{
            transition: "all 0.5s ease-in",
            transform:
              orderBy.find((o) => o.key === key)?.direction === "asc"
                ? "rotate(-180deg)"
                : "",
          }}
        />
      ) : (
        <Sort />
      )}
    </IconButton>
  );

  useEffect(() => {
    setCollaborators(results?.pages?.flat() ?? []);
  }, [results, setCollaborators]);

  return (
    <Modal
      {...props}
      sx={{
        position: "relative",
        maxHeight: "80%",
        width: "80%",
        overflowX: "hidden",
        overflowY: "auto",
        p: 1,
      }}
    >
      <IconButton
        size="small"
        onClick={props?.onClose}
        color="error"
        sx={{
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <Cancel />
      </IconButton>

      <ContentTable>
        <Table stickyHeader aria-label="listModels">
          <TableHead>
            <TableRow
              sx={{
                th: {
                  backgroundColor: "white",
                  "& p": {
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                  },
                  "& div": {
                    display: "flex",
                    alignItems: "center",

                    "& button": {
                      ml: 1,
                    },
                  },
                },
              }}
            >
              <TableCell>
                <div>
                  <Typography>Nome</Typography>
                  {getSortIcon("name")}
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <Typography>Email</Typography>
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <Typography>Data de criação</Typography>
                  {getSortIcon("date")}
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <Typography>Criado por</Typography>
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <Typography>Status</Typography>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Grid2
                    alignItems="center"
                    sx={{ minHeight: "65px !important" }}
                  >
                    <CircularProgress size={15} />
                    <Typography
                      paddingLeft={1}
                      variant="caption"
                      color="text"
                      sx={{ fontStyle: "italic" }}
                    >
                      Carregando lista de modelos...
                    </Typography>
                  </Grid2>
                </TableCell>
              </TableRow>
            ) : !collaborators?.length ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography
                    paddingLeft={1}
                    variant="caption"
                    color="text"
                    sx={{ fontStyle: "italic" }}
                  >
                    Nenhum usuário encontrado...
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              collaborators.map((item) => (
                <TableRow key={item.id} data-testid="rowUsers">
                  <TableCell>
                    <Typography>{item.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{item.email}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {new Date(item.createdAt).toLocaleDateString("pt-BR", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{item.createdByName}</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography>
                      {item.disabled ? (
                        <DoDisturb color="error" />
                      ) : (
                        <Check color="success" />
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            )}
            {hasNextPage && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  {isFetchingNextPage ? (
                    <Grid2
                      alignItems="center"
                      sx={{ minHeight: "65px !important" }}
                    >
                      <CircularProgress size={15} />
                      <Typography
                        paddingLeft={1}
                        variant="caption"
                        color="text"
                        sx={{ fontStyle: "italic" }}
                      >
                        Carregando itens...
                      </Typography>
                    </Grid2>
                  ) : (
                    <Button onClick={() => fetchNextPage()} sx={{ px: 2 }}>
                      <KeyboardDoubleArrowDown />
                      <Typography paddingLeft={2} variant="button">
                        Carregar mais...
                      </Typography>
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ContentTable>
    </Modal>
  );
};

export default CollaboratorListModal;
