import React from "react";
import MultiTab from "./MultiTab";
import { Link } from "react-router-dom";
import { Container } from "./styles";

interface ErrorPageProps {
  onReset?: () => void;
  statusCode?: number;
  message?: string;
}

const Error: React.FC<ErrorPageProps> = ({ statusCode, message, onReset }) => {
  return (
    <Container>
      <div className="help">
        <strong>
          <h1>{message ?? "A página solicitada está indísponivel"}</h1>
        </strong>
        <strong>
          <h2>
            Clique no link para ser direcionado para{" "}
            <Link onClick={onReset} to="/">
              Home
            </Link>
          </h2>
        </strong>
      </div>
    </Container>
  );
};

export const MultiTabError = MultiTab;
export default Error;
