import { Loading } from "components";
import { CompanyUser, Error, Home, LogsUser, MultiTabError } from "pages";
import { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import DossierModel from "pages/DossierModel";
import CollaboratorUser from "pages/CollaboratorUser";
import { ManagementStatus, TypeUser } from "types/enums";
import { useAuth } from "contexts";
import RecklessTrader from "pages/RecklessTrader";
import RecklessTraderReport from "pages/RecklessTraderReports";
import FontsReport from "pages/FontsReport";

const Search = lazy(() => import("pages/Search"));

const Router = () => {
  const { user } = useAuth();
  const [anotherTab, setAnotherTab] = useState(false);
  const routesList = [
    {
      path: "/",
      element: <Home />,
      auth: false,
      allowed: () => true,
    },
    {
      path: "/search",
      element: <Search />,
      auth: true,
      allowed: () => true,
    },
    {
      path: "/recklessTrader",
      element: <RecklessTrader />,
      auth: true,
      allowed: () =>
        user?.typeUser === TypeUser.USER ||
        user?.typeUser === TypeUser.ADM ||
        user.managementStatus?.includes(ManagementStatus.PODEN),
    },
    {
      path: "/recklessTraderReport",
      element: <RecklessTraderReport />,
      auth: true,
      allowed: () =>
        user?.typeUser === TypeUser.USER ||
        user?.typeUser === TypeUser.ADM ||
        user.managementStatus?.includes(ManagementStatus.PODEN),
    },
    {
      path: "/companies",
      element: <CompanyUser />,
      auth: true,
      allowed: () => user?.typeUser === TypeUser.ADM,
    },
    {
      path: "/fontsReport",
      element: <FontsReport />,
      auth: true,
      allowed: () => user?.typeUser === TypeUser.ADM,
    },
    {
      path: "/models",
      element: <DossierModel />,
      auth: true,
      allowed: () =>
        user?.typeUser === TypeUser.USER ||
        user?.typeUser === TypeUser.ADM ||
        user.managementStatus?.includes(ManagementStatus.MODEL),
    },
    {
      path: "/users",
      element: <CollaboratorUser />,
      auth: true,
      allowed: () =>
        user?.typeUser === TypeUser.USER ||
        user?.typeUser === TypeUser.ADM ||
        user.managementStatus?.includes(ManagementStatus.USER),
    },
    {
      path: "/logsUser",
      element: <LogsUser />,
      auth: true,
      allowed: () =>
        user?.typeUser === TypeUser.USER || user?.typeUser === TypeUser.ADM,
    },
    {
      path: "*",
      element: <Error />,
      auth: false,
      allowed: () => true,
    },
  ];

  useEffect(() => {
    const channel = new BroadcastChannel("legal-audit");
    channel.postMessage("another-tab");
    const timer = setTimeout(() => {
      channel.addEventListener("message", (msg) => {
        if (msg.data === "another-tab") {
          setAnotherTab(true);
        }
      });
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (anotherTab) {
    return <MultiTabError />;
  }

  return (
    <div style={{ height: "100%" }}>
      <BrowserRouter>
        <Suspense fallback={<Loading shown />}>
          <Routes>
            {routesList
              .filter((route) => route.allowed())
              .map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    route.auth ? (
                      <AuthRoute>{route.element}</AuthRoute>
                    ) : (
                      route.element
                    )
                  }
                />
              ))}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
};

export default Router;
