import {
  Download,
  KeyboardArrowDown,
  KeyboardDoubleArrowDown,
  Search,
  Sort,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { AppBar } from "components";
import moment from "moment";
import { useEffect, useState } from "react";
import { LogStatus, LogStatusText } from "types/enums";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { HeaderContainer } from "./styles";
import { Controller, useForm } from "react-hook-form";
import { Columns } from "props";
import { useLogUser } from "contexts";
import { Cnpj, Cpf } from "utils/masks";
import { useLocation } from "react-router-dom";
import React from "react";
import { exportReportFinancialFonts } from "controllers/LogUserController";

const LogsUser = () => {
  const theme = useTheme();
  const location = useLocation();
  const [dossierId, setDossierId] = useState<string | undefined>(
    location.state?.dossierId
  );
  const {
    logsUser,
    orderBy,
    setOrder,
    hasNextPage,
    handleFetchNextPage,
    isFetchingNextPage,
    isLoading,
    setEnableFecthUserLogs,
    getUserLogDetails,
  } = useLogUser();

  const [openLogs, setOpenLogs] = useState<{ [key: string]: boolean }>({});
  const [lodingLogs, setLoadingLogs] = useState<{ [key: string]: boolean }>({});
  const [logUserFiltered, setLogUserFiltered] = useState(logsUser);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const { control, watch } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
  });
  const data = watch();

  const handleSort = (key: Columns) => () => {
    setOrder((prev) => {
      const newOrder = [...prev];
      const oldOrderIdx = prev.findIndex((o) => o.key === key);
      if (oldOrderIdx === -1) {
        newOrder.push({
          key,
          direction: "asc",
        });
      } else if (newOrder[oldOrderIdx].direction === "asc") {
        newOrder[oldOrderIdx].direction = "desc";
      } else {
        newOrder.splice(oldOrderIdx, 1);
      }
      return newOrder;
    });
  };

  const expandLogs = async (id: string) => {
    try {
      setLoadingLogs((prevOpenLogs) => ({
        ...prevOpenLogs,
        [id]: true,
      }));

      if (!openLogs[id]) {
        await getUserLogDetails(id);
      }

      setOpenLogs((prevOpenLogs) => ({
        ...prevOpenLogs,
        [id]: !prevOpenLogs[id],
      }));
    } finally {
      setLoadingLogs((prevOpenLogs) => ({
        ...prevOpenLogs,
        [id]: false,
      }));
    }
  };

  const getSortIcon = (key: Columns) => (
    <IconButton size="small" onClick={handleSort(key)}>
      {orderBy?.find((o) => o.key === key) ? (
        <KeyboardArrowDown
          sx={{
            transition: "all 0.5s ease-in",
            transform:
              orderBy.find((o) => o.key === key)?.direction === "asc"
                ? "rotate(-180deg)"
                : "",
          }}
        />
      ) : (
        <Sort />
      )}
    </IconButton>
  );

  const onRequestExtract = async () => {
    setIsLoadingExport(true);
    try {
      await exportReportFinancialFonts();
    } finally {
      setIsLoadingExport(false);
    }
  };

  // Habilita o carregamento dos dados para a tela
  useEffect(() => {
    setEnableFecthUserLogs(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dossierId) {
      setLogUserFiltered(logsUser.filter((l) => l.dossierId === dossierId));
      setDossierId(undefined);
      return;
    }
    setLogUserFiltered(logsUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logsUser]);

  return (
    <Container
      sx={{
        minWidth: "90vw",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginTop: "6rem",
        minHeight: "90vh",
      }}
    >
      <AppBar />
      <Paper sx={{ flexGrow: 1 }}>
        <HeaderContainer>
          <Typography component="p" variant="h4" color="primary">
            Fontes Consultadas
          </Typography>

          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Controller
              control={control}
              name="filter"
              render={({ field: { onChange, value } }) => (
                <TextField
                  size="small"
                  label="Pesquisar"
                  variant="standard"
                  type="text"
                  placeholder="O que deseja buscar?"
                  sx={{ width: 250 }}
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    sx: {
                      pl: 1,
                    },
                    endAdornment: <Search />,
                  }}
                />
              )}
            />
            <Typography
              className="containerExtractReport"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={onRequestExtract}
                startIcon={
                  isLoadingExport ? (
                    <CircularProgress size={15} />
                  ) : (
                    <Download />
                  )
                }
                disabled={isLoadingExport}
              >
                {isLoadingExport ? "Exportando..." : "Exportar Relatório"}
              </Button>
            </Typography>
          </div>
        </HeaderContainer>
        <TableContainer
          sx={{
            px: 3,
            height: "100%",
            flexGrow: 1,
          }}
        >
          <Table
            stickyHeader
            aria-label="list-logs"
            sx={{ paddingBottom: "1rem" }}
          >
            <TableHead>
              <TableRow
                sx={{
                  th: {
                    backgroundColor: "white",
                    "& p": {
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                    },
                    "& div": {
                      display: "flex",
                      alignItems: "center",

                      "& button": {
                        ml: 1,
                      },
                    },
                  },
                }}
              >
                <TableCell>
                  <Grid>
                    <Typography>#</Typography>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Data</Typography>
                    {getSortIcon("date")}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>CPF/CNPJ</Typography>
                    {getSortIcon("document")}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography>Usuário</Typography>
                </TableCell>

                <TableCell>
                  <Typography>Empresa</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Descrição</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Detalhes</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <div>
                      <CircularProgress size={15} />
                      <Typography
                        paddingLeft={1}
                        variant="caption"
                        color="text"
                        sx={{ fontStyle: "italic" }}
                      >
                        Carregando registro de logs...
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              ) : !logsUser.length ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography
                      paddingLeft={1}
                      variant="caption"
                      color="text"
                      sx={{ fontStyle: "italic" }}
                    >
                      Nenhum log encontrado...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                logUserFiltered
                  .filter((l) =>
                    data.filter
                      ? l.description
                          .toLowerCase()
                          .includes(data.filter.toLocaleLowerCase()) ||
                        new Date(l.createdAt)
                          .toLocaleDateString("pt-BR", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })
                          .includes(data.filter)
                      : logUserFiltered
                  )
                  .map((log, idx) => (
                    <React.Fragment key={log.id}>
                      <TableRow
                        data-testid="rowLogsUser"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography color="primary">
                            {(idx + 1).toString().padStart(2, "000")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ whiteSpace: "nowrap" }}>
                            {moment(log.createdAt).format("DD/MM/yyyy HH:mm")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ whiteSpace: "nowrap" }}>
                            {log.dossierDocument
                              ? log.dossierDocument.length === 11
                                ? Cpf(log.dossierDocument)
                                : Cnpj(log.dossierDocument)
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ whiteSpace: "nowrap" }}>
                            {log.nameUser ?? "-"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ whiteSpace: "nowrap" }}>
                            {log.companyName?.trim() ? log.companyName : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{log.description}</Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              expandLogs(log.id);
                            }}
                          >
                            {lodingLogs[log.id] ? (
                              <CircularProgress size={15} />
                            ) : openLogs[log.id] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow key={`${log.id}-collapse`}>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={7}
                        >
                          <Collapse in={openLogs[log.id]} timeout="auto">
                            {log.fontsLogs?.length > 0 && (
                              <Box sx={{ margin: 1.5 }}>
                                <Typography
                                  variant="h5"
                                  gutterBottom
                                  component="div"
                                  color={theme.palette.primary.main}
                                >
                                  Detalhes
                                </Typography>
                                <Container>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                      <Typography
                                        variant="body1"
                                        color="text.primary"
                                      >
                                        <strong>Empresa:</strong>{" "}
                                        {log.companyName || "Indisponível"}
                                        {log.companyEmail
                                          ? ` (${log.companyEmail})`
                                          : ""}
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        color="text.primary"
                                      >
                                        <strong>Usuário:</strong>{" "}
                                        {log.nameUser || "Indisponível"}
                                        {log.emailUser
                                          ? ` (${log.emailUser})`
                                          : ""}
                                      </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                      <Typography
                                        variant="body1"
                                        color="text.primary"
                                      >
                                        <strong>Descrição:</strong>{" "}
                                        {log.description}
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        color="text.primary"
                                      >
                                        <strong>Criado em:</strong>{" "}
                                        {new Date(
                                          log.createdAt
                                        ).toLocaleDateString()}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Container>
                                <Typography
                                  sx={{ mt: 2 }}
                                  variant="h5"
                                  component="div"
                                  color={theme.palette.primary.main}
                                >
                                  Fontes
                                </Typography>
                                <Container>
                                  <TableContainer>
                                    <Table>
                                      <TableHead>
                                        <TableRow sx={{ height: 40 }}>
                                          <TableCell sx={{ py: 0.5 }}>
                                            <Typography
                                              variant="h6"
                                              fontWeight={500}
                                            >
                                              Nome
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography
                                              variant="h6"
                                              fontWeight={500}
                                            >
                                              Status
                                            </Typography>
                                          </TableCell>
                                          <TableCell align="right">
                                            <Typography
                                              variant="h6"
                                              fontWeight={500}
                                            >
                                              Log
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {log.fontsLogs?.map(
                                          (fontLog, index) => (
                                            <TableRow key={index}>
                                              <TableCell sx={{ p: 0.5 }}>
                                                <Typography
                                                  variant="body1"
                                                  fontWeight={400}
                                                >
                                                  {fontLog.name}
                                                </Typography>
                                              </TableCell>
                                              <TableCell sx={{ p: 0.5 }}>
                                                <Typography
                                                  variant="body1"
                                                  fontWeight={400}
                                                  color={
                                                    fontLog.status ===
                                                    LogStatus.RESOLVED
                                                      ? "green"
                                                      : fontLog.status ===
                                                        LogStatus.UNSOLVED
                                                      ? "error"
                                                      : "info"
                                                  }
                                                >
                                                  {
                                                    LogStatusText[
                                                      fontLog.status
                                                    ]
                                                  }
                                                </Typography>
                                              </TableCell>

                                              <TableCell
                                                align="right"
                                                sx={{ py: 0.5 }}
                                              >
                                                <Tooltip
                                                  title={
                                                    fontLog.log ||
                                                    "Sem informações"
                                                  }
                                                  arrow
                                                  disableHoverListener={
                                                    !fontLog.log
                                                  }
                                                >
                                                  <span>
                                                    <IconButton
                                                      size="small"
                                                      disabled={!fontLog.log}
                                                    >
                                                      <Visibility />
                                                    </IconButton>
                                                  </span>
                                                </Tooltip>
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Container>
                              </Box>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
              )}
              {hasNextPage && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    {isFetchingNextPage ? (
                      <div>
                        <CircularProgress size={15} />
                        <Typography
                          paddingLeft={1}
                          variant="caption"
                          color="text"
                          sx={{ fontStyle: "italic" }}
                        >
                          Carregando itens...
                        </Typography>
                      </div>
                    ) : (
                      <Button
                        onClick={() => handleFetchNextPage()}
                        sx={{ px: 2 }}
                      >
                        <KeyboardDoubleArrowDown />
                        <Typography paddingLeft={2} variant="button">
                          Carregar mais...
                        </Typography>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default LogsUser;
